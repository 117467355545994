.main-dropdown {
  width: 665px !important;
  padding: 5px 10px;
  flex-wrap: wrap;
}
.sidebar-enable .vertical-menu {
  background: #e6e6fa !important;
}
.sidebar-enable .menu-side-welcome{
  display: none;
}

.dropdown-head {
  overflow-x: hidden;
  margin-bottom: 10px;
}

.btn-modal-span {
  color: #bf1e30;
  font-size: 24px;
  line-height: 1px;
}

.btn-modal-close {
  border: none;
  background-color: transparent;
}

.id-width {
  width: 8.33%;
}
.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

/* News Css Changes */
.border-radius {
  border-radius: 2px !important;
  font-size: 14px;
}

.bg-green {
  background-color: #bdb2ff !important;
  color: white !important;
}
.bg-white {
  background-color: white !important;
}

.bg-light-green {
  background-color: #34c38f !important;
}

.btn-green {
  /* background-color: #97ecf1 !important; */
  /* color: #222 !important; */
  overflow: hidden;
  position: relative;
  /* box-shadow: 2px 2px 20px #fff inset; */
}
.shadow-smallcard {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
}

.btn-green::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0%;
  left: 50%;
  height: 100%;
  width: 0px;
  background-color: #ffffff3f;
  /* transform: rotate(20deg); */
  transition: all 0.6s;
}

.btn-green:hover::after {
  left: 0;
  width: 100%;
}

.btn-red {
  background-color: #b53443 !important;
  color: #fff !important;
}

.btn-cyan {
  background-color: #3b2d8e !important;
  color: #fff !important;
  font-weight: 500;
}

.btn-yellow {
  background-color: #fec868 !important;
  color: #775111;
  font-weight: 500;
}

.btn-purple {
  background-color: #3b2d8e !important;
  color: white !important;
  font-weight: 500;
}

.text-purple {
  color: #3b2d8e !important;
}
.text-light-red {
  color: rgb(115, 17, 115);
}

/* colors */
.bg-light-cyan {
  background-color: #97ecf1 !important;
}

.bg-light-blue {
  background-color: #dffdff !important;
  color: #167a7f !important;
}

.bg-light-purple {
  background-color: #bdb2ff !important;
}

.bg-light-yellow {
  background-color: #fec868 !important;
}

.bg-light-pink {
  background-color: #fad1fa !important;
}
.bg-light-purple2 {
  background-color: rgb(230, 230, 250);
}
.bg-light-red {
  background-color: rgb(238, 209, 193);
}

.form-control:focus,
.form-select:focus {
  border: 1px solid #5c76f5 !important;
}

/* verticle side tab */
.nav-pills .nav-link {
  border-bottom: 1px solid #99999959;
  margin-bottom: 0 !important;
  padding: 12px 14px;
  border-radius: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #9e90f2 !important;
  border-radius: 2px;
  color: #fff;
}

.side-tab {
  box-shadow: 2px 2px 5px #06000924;
  background-color: #fad1fa3f !important;
  padding: 12px !important;
}
.shadow {
}

/* ---------------------- */
.icon-size {
  font-size: 12px;
}

.icon-center {
  display: inline-block;
  transform: translateY(2px);
}

/* .row-hover tr:hover{
  background-color: #e4e6e1 !important;
} */
.row-hover:hover td {
  /* border: none; */
}

tbody tr:nth-child(even) {
  background-color: #fad1fa0e;
}

/* sticky table */
.fix-header {
  position: fixed;
  background-color: whitesmoke;
  z-index: 40;
  top: 19.2%;
  width: 91.2%;
  left: 4.4%;
}

.fix-header tr {
  display: flex;
}
.bg-custom-grey {
  color: #cccbcb;
}

/* fixed buttons */
.fixed-buttons {
  min-width: 50px;
  align-items: flex-end;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 50;
}

.fixed-buttons .fix-btn {
  min-width: 50px;
  display: flex;
  align-items: center;
  border-radius: 0 !important;
}

.fix-btn span {
  display: inline-flex;
  transition: all 0.3s;
  width: 0;
  overflow: hidden;
}

.fix-btn:hover span {
  width: 55px;
}

.fix-btn:hover .add-family {
  width: 80px;
}

.fix-btn i {
  transform: translateX(5px);
}

.fix-btn:hover i {
  transform: translateX(0px);
}

.add-btn {
  height: 38px;
}

/* fixed side tab */
.fixed-side-tab {
  position: fixed;
  width: 180px;
  top: 0%;
  left: 21.9%;
}

.form-check-input {
  background-color: #adadada6 !important;
  border: none;
}

.form-check-input:checked {
  background-color: #7a74bd !important;
  border: 1px solid #bdb2ff;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

.bd-accordian-btn:not(.collapsed) {
  background-color: #dfffdf;
}

.text-black {
  color: black;
}

.btn-modal-span {
  color: #6251c1;
  font-size: 24px;
  line-height: 1px;
}

.btn-modal-close {
  border: none;
  background-color: transparent;
}

.id-width {
  width: 8.33%;
}

.table {
  vertical-align: middle;
}

.ck.ck-editor__main > .ck-editor__editable {
  height: 180px !important;
}

/* on edit page */
.note-sticky-form .ck.ck-editor__main > .ck-editor__editable {
  height: 365px !important;
}

.contract-editor .ck.ck-editor__main > .ck-editor__editable {
  height: 700px !important;
}

.notes .ck.ck-editor__main > .ck-editor__editable {
  height: 100px !important;
}

.profileImg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.bd-span-input .input-span {
  padding: 0.47rem 0.75rem;
  border-radius: 2px;
  font-size: 14px;
  border: 1px solid var(--bs-input-border-color);
  background-color: var(--bs-gray-200);
  color: var(--bs-gray-700);
}

.custom-file-upload {
  background-color: #f3f6f9;
  cursor: pointer;
  height: 45px;
  font-size: 16px;
}

.pr-row {
  padding-right: calc(var(--bs-gutter-x) * 0.5);
}

.pl-row {
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.display-none {
  display: none;
}

.select__menu {
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  /* position: absolute !important; */
  z-index: 9999 !important;
}

/* Form Fields */
.form-field {
  padding: 10px 13px;
  width: 100%;
  background-color: #fff;
  border: 0;
  border-radius: 3px;
  font-size: 14.5px;
  border: 1px solid #beb2ff88;
}

.form-field:focus {
  outline: 2px solid #beb2ff !important;
  background-color: #fec76819;
}

.dynamic-field {
  animation: dynamicField 0.8s ease-in-out 0s 1 normal none running;
}

@keyframes dynamicField {
  0% {
    background-color: #fec76853;
  }

  100% {
    background-color: #fff;
  }
}

/* react-select field */
.select__control {
  background-color: #fff !important;
  padding: 4px 10px !important;
  border: 1px solid #beb2ff88 !important;
  /* border: 1px solid #beb2ffc1 !important; */
}

.select__multi-value__label,
.select__multi-value__remove {
  background-color: #3b2d8e !important;
  color: white !important;
  border-radius: 0 !important;
}

.table-green {
  background-color: #fad1fa;
  color: #732b73;
}

/* .table-bordered{
  border: 1px solid lightgrey!important;
} */

/* #5f674c */

.row-delete-btn {
  position: absolute;
  right: 2%;
  top: 17%;
}

/* sticky notes */
.sticy-note-btn {
  position: fixed;
  top: 46%;
  right: -43px;
  padding: 10px 20px;
  font-size: 16px;
  transform: rotate(90deg);
  background-color: #97ecf1;
  border: 0;
  color: #167a7f;
  font-weight: 600;
}


form label.fs-5 {
  font-size: 14px !important;
}

.invoice-line {
  background-color: #999;
  height: 1px;
  margin-top: 30px;
}

.invoice-page {
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.324);
}

/* Accordian */
.accordion-item .accordion-button {
  background-color: #fad1fa92;
  color: #771d77;
}

.diet-items li {
  padding: 2px 0;
}

/* resizaable */
.react-resizable {
  position: relative;
  right: 5% !important;
  background-color: #fff;
  padding: 20px;
  box-shadow: 2px 2px 20px #999999a5;
}

.react-resizable:active {
  cursor: grab;
}

.react-resizable-handle {
  position: absolute;
  width: 30px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  /* padding: 0 3px 3px 0; */
  right: 0% !important;
  bottom: 0 !important;
}

.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}

.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}

.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}

.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

/* login page */
.bd-eye-pass {
  position: absolute;
  right: 11px;
  top: 58%;
  cursor: pointer;
}

.bd-eye-pass i {
  font-size: 15px;
}

/* Loader style */
.loader-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #111111b3;
  z-index: 999999;
  display: grid;
  place-items: center;
}

.loader {
  border: 12px solid #fff;
  /* Light grey */
  border-top: 12px solid #8474de;
  border-bottom: 12px solid #ebb559;
  /* border-right: 12px solid #fad1fa;
  border-left: 12px solid #fad1fa;
   */
  /* Blue */
  border-radius: 50%;
  width: 90px;
  height: 90px;
  animation: spin 1.2s linear infinite;
}

.fc-event-time {
  display: none;
}

/* diet-plan-tab */
.diet-plan-tab {
  height: 65vh;
  display: block;
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-width: thin;
}

.diet-plan-tab .nav-item {
  width: 100%;
}

.view-box {
  padding-left: 0 !important;
  margin-top: 10px;
}

.view-box li {
  padding: 15px 25px;
  background-color: #06000916;
  list-style: none;
  margin-bottom: 12px;
  font-size: 15px;
}

.dash-followUp p {
  margin-bottom: 0 !important;
  font-size: 14px;
}

.records {
  background-color: #8474de18;
  padding: 10px 6px;
  margin-left: 20px;
  min-width: 100px !important;
  text-align: center;
}

.records2 {
  min-width: 80px;
}

.record-light-green {
  background-color: #0cd57739;
}

.record-light-purple {
  background-color: #97ecf158;
}

.record-light-pink {
  background-color: rgba(255, 0, 0, 0.186);
}

/* add client */

.plan-tab {
  background-color: #9f90f22f;
  /* padding: 5px; */
}

.plan-tab a {
  color: #5c0385 !important;
  padding: 13px 11px !important;
  /* font-size: 16px; */
  border-left: 1px solid #9f90f2;
}

.tab-circle {
  border: 2px solid #9f90f2 !important;
  color: #9f90f2 !important;
  background-color: #fff;
}
.color-card {
  background-color: #f2f2ff;
}
.followup {
  padding: 20px 15px !important;
  position: relative;
}
.btn-success {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-edit {
  height: 25px;
  width: 25px;
}
.active-plan-bg {
  background-color: #9f90f2;
  border: 2px solid #9f90f2 !important;
  color: white !important;
}

.active-plan-tab {
  background-color: #fad1fa;
  border-left: 4px solid #9f90f2;
}
.bg-danger {
  background-color: #e6e6fa !important;
  color: #3b2d8e;
}
.fc-time {
  color: white ;
}
.fc-title {
  color: white;
}

.terms > p {
  margin-bottom: 0 !important;
}
.logout-btn i,
p {
  font-size: 16px;
  transition: all 0.3s;
  color: black;
}
.logout-btn:hover {
  cursor: pointer;
}

.logout-btn:hover p {
  color: #3b2d8e;
  cursor: pointer;
}
.logout-btn:hover i {
  color: #3b2d8e;
  cursor: pointer;
}
.icon-card {
  background: none !important;
  font-size: 22px;
}
.icon-card i {
  font-size: 22px !important;
}

.card-stats:hover {
  transform: scale(1.1);
}
.sidebar-title {
  color: #3b2d8e;
}
.ncp-table {
  border-collapse: initial;
  border-spacing: 0 9px !important;
  border-width: 0px 0px !important;
}
.ncp-table tbody tr td {
  background-color: #fff !important;
  padding: 14px 11px 15px 15px;
}
.table-bordered > * > * {
  border-width: 0 0px !important;
}
.ncptable-header {
  font-size: 12px;
  color: #3b2d8e;
  font-weight: 500;
}
.ribbon-header {
  font-size: 14px;
  font-weight: 500;
}
.box {
  position: relative;
}

.ribbon {
  -webkit-clip-path: polygon(90% 1%, 100% 50%, 90% 100%, 0 100%, 0 50%, 0 0);
  clip-path: polygon(90% 1%, 100% 50%, 90% 100%, 0 100%, 0 50%, 0 0);
  color: black;
  left: 0;
  padding: 6px 12px;
  position: absolute;
  text-transform: uppercase;
  top: 8px;
  width: 100%;
}

.fc {
  background-color: white;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #7a74bd;
  color: white;
}
.fc .fc-toolbar.fc-header-toolbar {
  padding: 15px;
}
.fc th {
  border: 1px solid #e0e0e0;
}
/* .fc-day-today{
  background-color: #167a7f!important;
} */

/* .btn-group button{
  background-color: #7a74bd;
  color: white;
  border: none;
} */

/* .btn:disabled{
  background-color: #3b2d8e!important;
  
  font-weight: 500;
} */

.btn-primary {
  background-color: white !important;
  border: 1px solid #3b2d8e !important;
  color: #3b2d8e !important;
}
.fc td {
  border: 1px solid #e0e0e0;
}
.fc .fc-day-other .fc-daygrid-day-top {
  justify-content: center;
}
.fc .fc-daygrid-day-top {
  justify-content: center;
}
.btn-small:hover {
  color: white !important;
}

.fc-toolbar-chunk {
  display: flex;
}
/* .btn:hover{
  color: #bdb2ff!important;
} */

/* .btn .active{
  background-color: #167a7f!important;
} */

/* responsive table */
@media screen and (max-width: 768px) {
  .logo h2 {
    font-size: 20px !important;
  }

  .responsive thead {
    visibility: hidden;
    height: 0;
    position: absolute;
  }

  .responsive tbody tr:nth-child(even) {
    background-color: #fad1fa40;
  }

  .responsive tr {
    display: block;
    margin-bottom: 9px ;
    background-color: #fad1fa40;
  }

  .responsive td {
    border: 1px solid lightgray;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    border-bottom: none;
    font-size: 13px;
    padding: 0;
    text-align: left !important;
    padding-right: 10px;
  }
  .logout-btn i ,.logout-btn p{
    font-size: 13px;
  }

  .responsive td:nth-child(odd) {
    background-color: #fff;
  }

  .responsive td::before {
    content: attr(data-label);
    font-weight: 700;
    /* text-transform: uppercase; */
    width: 38%;
    border-right: 1px solid lightgray;
    height: 100%;
    padding: 12px 10px;
    padding-right: 5px;
    margin-right: 10px;
    color: #732b73;
  }

  .mobile-sidetab {
    position: fixed;
    height: 100%;
    top: 0;
    left: -70%;
    width: 60%;
    background-color: #fff;
    padding: 0;
    transition: all 0.3s;
    z-index: 9999999;
  }
  .sidebar-enable .menu-side-welcome{
    display: block;
  }
  
  #sidebar-menu h3{
    font-size: 20px;
  }
  .showTab {
    left: 0;
  }

  .loader {
    border: 8px solid #fff;
    /* Light grey */
    border-top: 8px solid #8474de;
    border-bottom: 8px solid #ebb559;
    /* border-right: 12px solid #fad1fa;
    border-left: 12px solid #fad1fa;
     */
    /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .fixed-side-tab {
    display: none;
  }
}
.in-active .fc-daygrid-event-dot {
  transform: scale(1);
  border: 4px solid #bf1e30;
}
.in-active .fc-event-title {
  display: none;
}
.in-active {
  position: absolute;
  top: -10%;
}
.page-title-box {
  box-shadow: 6px 6px 5px #0000001a;
  padding: 25px 52.5px 20px 47.25px;
}
.page-title-box h4 {
  color: #594fa7;
  font-size: 22px !important;
  font-weight: 600;
  text-transform: uppercase;
}
.mm-active .active {
  color: #000 !important;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0;
}
.card-stats {
  transition: all 0.3s;
}
.table th {
  background-color: #3b2d8e;
  color: #fff;
  font-weight: 600;
}
.shadow-card {
  box-shadow: 4px 4px 5px #0000001a;
}
.modal-header {
  justify-content: space-between;
}
.sidebar-enable #sidebar-menu > ul > li {
  padding: 5px 0px !important;
}
.sidebar-enable #sidebar-menu > ul > li:hover > a {
  position: relative !important;
  transition: none !important;
  width: 260px !important;
  z-index: 999;
  display: flex;
  align-items: center;
}
.sidebar-enable #sidebar-menu > ul > li:hover > a i{
  padding: 0;
}
.sidebar-enable .main-logo {
  width: 40px !important;
  height: auto !important;
  object-fit: contain;
}

/* flip box */
.inner-card {
  transition: transform 0.8s;
  transform-style: preserve-3d;
  perspective: 1000px;
}

.flip {
  transform: rotateY(180deg);
}
.back-card {
  transform: rotateY(180deg);
}
.terms p{
  font-size: 14px;
}
.sidebar-enable .sub-menu{
  padding-left: 0px;
}
.sidebar-enable .sub-menu span{
  display: none !important;
}
.sub-menu{
  padding-left: 30px;
}
.sub-menu li{
  padding: 0px 0px !important;
}
.sub-menu li a{
  font-size: 15px !important;
}
.sub-menu li a i{
  font-size: 15px !important;
}
@media screen and (max-width:786px) {
  .page-content{
    padding: 10px;
  }
  .back-btn{
    display: none;
  }
  .ncptable-header{
    display: none;
  }
  .page-title-box{
    padding: 15px 25px;
  }
  .page-title-box h4{
    font-size: 16px !important;
    margin-bottom: 0;
  }
  .fixed-buttons-modified{
    top: 5.8%;
    left: 0;
  }
  .ncp-table tbody tr td{
    padding: 3px 10px;
    width: 100%;
  }
  .mobile-flex-wrap{
    flex-wrap: wrap;
  }
  .sticy-note-btn{
    right: -42px;
  }
}