/* .text-muted {
    margin-top: -20px;
} */
/* increase calendar width */
#date input[type="date"] {
  display: block;
  margin: 0 auto; /* This centers the input horizontally */
}
body[data-layout="horizontal"] .page-content {
  padding: calc(55px + 24px) calc(0px) 60px calc(0px) !important;
}

.fc-event-title-container {
  white-space: normal !important;
}

.fc-daygrid-event {
  white-space: normal !important;
  overflow: visible !important;
  text-overflow: clip !important;
}

.fc-daygrid-event-dot {
  display: none;
}

/* .fc-daygrid-event {
    display: flex;
} */
.fc-daygrid-event a {
  color: inherit; /* Set the color to inherit from the parent element */
  text-decoration: none;
}
.col-lg-3 {
  display: none;
}

.col-lg-9 {
  width: 100% !important;
}
/* fixed button start*/
.fixed-buttons-modified {
  position: fixed;
  top: 200px;
  left: 0;
  /* Adjust left positioning */
  z-index: 200;
  width: auto;
}

/* fixed button slide and hide   */
.fixed-buttons-modified {
  position: fixed;
  top: 13.8%;
  left: 270px;
  z-index: 4;
  /* transform: translateY(-50%); */
  /* transition: all 0.6s ease; */
}
.sidebar-enable .fixed-buttons-modified{
  left: 65px;
}

.fixed-buttons-modified .external-event {
  display: flex;
  background-color: #343a40;
  border-color: #343a40;

  width: 35px;
  height: 35px;
  text-align: center;
  cursor: pointer;
  transition: all 0.6s ease-in;
  overflow: hidden;
  /* Hide overflowing text */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.fixed-buttons-modified .external-event:hover {
  width: auto;
  /* transform: translateX(50px); */
  /* Expands the width on hover */
  /* color: #fff; */
  /* Make the text visible on hover */
}

/* fix btn icon */
.fix-btn-icon {
  position: relative;
  left: -8px;
}

.fix-button1 {
  background-color: rgb(115, 215, 138) !important;
}
.fix-button2 {
  background-color: rgb(27, 191, 64) !important;
}
.fix-button3 {
  background-color: rgb(255, 196, 88) !important;
}
.fix-button4 {
  background-color: #e8b724 !important;
}
.fix-button5 {
  background-color: rgb(164, 85, 164) !important;
}
.fix-button6 {
  background-color: rgb(87, 5, 87) !important;
}
.fix-button7 {
  background-color: #885688 !important;
}

/* fixed button end*/

/* events css start */
.div-cd {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 3px;
}
.fc-time {
  display: flex;
  justify-content: space-between;
  gap: 3px;
}
/* events css end */

/* aligning text of event */
.fc-title {
  text-align: start;
}

/* time picker */
.tp_inputBox {
  color: var(--bs-gray-700) !important;

  font-size: 0.8125rem !important;
  font-weight: 400 !important;
  /* padding: 0.47rem 0.75rem!important; */
  padding: 7px 10px 7px 4px !important;
}
.tp_inputWrapper {
  border: 1px solid var(--bs-input-border-color) !important;
  border-radius: 0.25rem !important;
  background-color: var(--bs-custom-white) !important;
  color: black !important;
}
.tp_option {
  padding-right: 25px !important ;
}
.tp_dropdownWrapper.tp_dropdownActive {
  padding: 0px !important;
}
.tp_secondWrapper {
  display: none !important;
}
.tp_optionsContainer {
  padding: 0 17px 8px !important;
}

/* add modal btns */
.add-modal-btn {
  display: flex;
  gap: 5px;
  width: fit-content;
  margin-top: 20px !important;
}

/* modal design */

.edit-modal-body {
  background-color: lavender;
  /* width: 560px !important; */
}
.edit-modal-header {
  background-color: darkslateblue;
  color: white;
  /* width: 560px !important; */
}
.add-modal-header {
  /* background-image: linear-gradient(to top, #48c6ef 0%, #6f86d6 100%); */
  background: aliceblue;
  color: #3b2d8e;
  border-bottom: none !important;
}
.add-modal-header h5{
  font-weight: 600;
}
.add-modal-body {
  background-color: aliceblue;
}

/* time picker  */
.MuiInputBase-root {
  font-size: 14px;
  color: #6a6767 !important;
  background-color: white !important;
  height: 38px !important;
  overflow: clip !important;

  font-size: 0.8125rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: var(--bs-gray-700) !important;
  background-color: var(--bs-custom-white) !important;
}
.css-mvmxd9-MuiTypography-root-MuiPickersToolbarText-root.Mui-selected {
  color: currentColor !important;
}
.css-7kirvq-MuiTypography-root-MuiPickersToolbarText-root.Mui-selected {
  color: currentColor !important;
}
.MuiDialogActions-root {
  display: none !important;
}
.css-4f0ona-MuiClock-clock {
  height: 200px !important;
  width: 200px !important;
}

.css-zddqwy-MuiClockNumber-root {
  height: 18px !important;
  width: 10px !important;
  left: calc((114% - 36px) / 2) !important;
}

.css-d0vs79-MuiClockPointer-root {
  height: 80px !important;
}
.MuiClockPointer-root {
  height: 80px !important;
}
/* time picker end */

/* modal */
.modal {
  --bs-modal-width: 570px !important;
}

/* icon color */
.icon1 {
  color: rgb(49, 176, 49);
}

.icon2 {
  color: green;
}

.icon3 {
  color: #d98f07;
}
.icon4 {
  color: #bf8925;
}
.icon5 {
  color: purple;
}
.icon6 {
  color: #cc37cc;
}
.icon7 {
  color: purple;
}

/* modal button */
.modal-btn-main1 {
  display: flex;
}
.modal-btn-main2 {
  display: flex;
}
.modal-btn {
  width: auto;
  font-size: 10px !important;
  display: flex;
  /* padding-left: 20px!important; */
  padding: 5px 15px 5px 15px !important;
}

.diet-title {
  font-size: 11px !important;
  padding-top: 1px !important;
}

.tripple-dot {
  /* padding-top: 0px!important; */
  padding: 0px 2px 0px 2px !important;
}
.diet-time {
  font-size: 11px !important;
}
.action-drop {
  z-index: 9999 !important; /* Set a higher z-index value */
  min-width: auto !important;
}

.fc .fc-daygrid-event {
  z-index: auto !important;
}

.selected {
  background-color: #48c6ef !important;
  transition: all 0.3s ease-in-out;
}

.delete-modal {
  height: 500px !important;
}
.modal.fade .modal-dialog {
  transition: none !important;
  transform: none !important;
}

.edit-modal {
  --bs-modal-width: 50vw !important;
}
