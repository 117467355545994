
/* Edit page */
.edit-contact-column {
    width: 19%;
}

.dashed-border-bottom {
    border-bottom: 2px dashed lightgray;
}

.verti-timeline {
    border-left: 3px solid var(--bs-gray-400) !important;
    padding: 20px 0 15px 0;
    margin-left: 100px !important;
}

.timeline-icon {
    transform: translateX(-2px);
}

.timing {
    position: absolute;
    left: -7%;
    top: 0;
}

.border-left {
    border-left: 3px solid var(--bs-gray-400) !important;
}

.userImg {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50px;
    border: 3px solid lightgray;
    position: relative;
    right: 20%;
}

.notes-box {
    margin-left: 20px !important;
    padding: 0 !important;
}

.note {
    background-color: #f7f5ff;
    position: relative;
}

.note-icon {
    color: #f7f5ff;
    position: absolute;
    top: 20%;
    left: -1.2%;
    transform: rotate(-90deg);
}

.edit-delete-btn {
    background-color: #fff !important;
    padding: 8px;
    border-radius: 5px !important;
    font-size: 13px !important;
}


.notes-sidebar {    
    position: fixed;
    z-index: 9999;
    padding: 20px;
    background-color: white;
    box-shadow: 2px 2px 50px #11111142;
    width: 75%;
    top: 22%;
    right: -100%;
    transition: .5s;
}

.show-notes-sidebar {
    right: 0%;
}

.cursor-pointer {
    cursor: pointer;
}