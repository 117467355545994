// 
// Widgets.scss
// 

.mini-stats-wid{
    .mini-stat-icon{
        overflow: hidden;
        position: relative;
        &:before, &:after{
            content: "";
            position: absolute;
            width: 8px;
            height: 120%;
            background-color: rgba($white,.2);
            left: 15px;
            transform: rotate(32deg);
            top: -10px;
            transition: all 0.4s;
        }

        &::after{
            left: -20px;
            width: 12px;
            top: 0;
            height: 120% !important;
            transition: all 0.2s;
        }
    }

    &:hover{
        .mini-stat-icon{
            &::after{
                left: 70px;
            }
        }
    }
}


